export const JOB_STATUS = {
  FAILED: '-1',
  PENDING: '0',
  PRINTING: '1',
  PACKING: '2',
  CHECKING: '3',
  DELIVERY: '4',
  CORRECT: '5',
  COMPLETED: '6',
  CREATED: '7',
};

export const HEADER_VALUE = {
	ALL_JOBS: 'ALL_JOBS',
	PENDING: JOB_STATUS.PENDING,
	PRINTING: JOB_STATUS.PRINTING,
	PACKING: JOB_STATUS.PACKING,
	CHECKING: JOB_STATUS.CHECKING,
	DELIVERY: JOB_STATUS.DELIVERY,
	CORRECT: JOB_STATUS.CORRECT,
	COMPLETED: JOB_STATUS.COMPLETED,
};

export const CHECKING_STATUS = {
	PENDING: '0',
	PASSED: '1',
	FAILED_AS_INCORRECT_DRUG: '2',
	FAILED_AS_INCORRECT_QUALITY: '3',
	FAILED_AS_SPECIFIC_REASON: '4',
};

export const CHECKING_LABEL_MAP = {
	[CHECKING_STATUS.FAILED_AS_INCORRECT_DRUG]: 'REJECT_MODAL_OPTION_INCORRECT_DRUG',
	[CHECKING_STATUS.FAILED_AS_INCORRECT_QUALITY]: 'REJECT_MODAL_OPTION_INCORRECT_QTY',
	[CHECKING_STATUS.FAILED_AS_SPECIFIC_REASON]: 'REJECT_MODAL_OPTION_OTHER',
};

export const DISTRIBUTE_STATUS = {
	PICKUP: '0',
	DELIVERY: '1',
};

export const DISTRIBUTION_LABEL_MAP = {
	[DISTRIBUTE_STATUS.PICKUP]: 'DISTRIBUTION_PICK_UP',
	[DISTRIBUTE_STATUS.DELIVERY]: 'DISTRIBUTION_DELIVERY',
};

export const FILTER_PACK_ALL = 'FILTER_PACK_ALL';

export const MAX_PACK_SIZE = 8;

export const CREATED_DATE = {
	ALL: 'ALL',
	LAST_14: 'LAST_14',
	LAST_30: 'LAST_30',
};

export const CREATED_DATE_OPTIONS = [
	{ label: 'All', value: CREATED_DATE.ALL },
	{ label: 'Last 14 days', value: CREATED_DATE.LAST_14 },
	{ label: 'Last 30 days', value: CREATED_DATE.LAST_30 },
];

export const CREATED_DATE_OPTIONS_LABEL_14DAY = 'Last 14 days';

export const JOB_STAGES = {
	PRINTING: 'packJobPrinting',
	PACKING: 'packJobPacking',
	CHECKING: 'packJobChecking',
	DISTRIBUTION: 'packJobDistribution',
};

export const PACK_JOB_SKIPPING = {
  PROFILE_UPDATE: 0,
  IMCOMPLETE_SUPPLY: 1,
  OTHER: 2,
};

export const SKIP_OPTIONS = [
  { label: 'Profile Update', value: PACK_JOB_SKIPPING.PROFILE_UPDATE },
  { label: 'Incomplete Supply', value: PACK_JOB_SKIPPING.IMCOMPLETE_SUPPLY },
  { label: 'Other', value: PACK_JOB_SKIPPING.OTHER },
];

export const PPAK_VALUE = '3';

export const PACK_TYPE_LABEL = {
  ALL_TYPE: 'All',
  MYPAK_SOLID_PERF: 'MYPAK - SOLID & PERF',
  MYPAK_TRIFOLD: 'MYPAK - TRIFOLD',
  MEDICO: 'MEDICO PAK',
};

export const CLIENT_PACKTYPE = [
  { label: PACK_TYPE_LABEL.ALL_TYPE, value: FILTER_PACK_ALL },
  { label: PACK_TYPE_LABEL.MYPAK_SOLID_PERF, value: '0,0,1' },
  { label: PACK_TYPE_LABEL.MYPAK_TRIFOLD, value: '0,2' },
  { label: PACK_TYPE_LABEL.MEDICO, value: '2,3' },
  // { label: 'PPAK', value: '3,0' } MC-593015
];

export const MESSAGE_CODE = {
  NOT_ASSIGNED_MEDICATION: 70,
};
